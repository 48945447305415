// Функція пошуку всіх сусідніх елементів
 // element - елемент, до якого шукаємо сусідні елементи, direction - напрямок пошуку (previous або next)

const findSiblingElements = (element, direction) => {
    const siblings = []; // масив для зберігання сусідніх елементів
    let sibling = direction === 'previous' ? element.previousElementSibling : element.nextElementSibling; // перевіряємо напрямок пошуку

    while (sibling) { // поки є сусідні елементи
        siblings.push(sibling); // додаємо їх в масив
        sibling = sibling[direction + 'ElementSibling']; // перевіряємо напрямок пошуку
    }

    return siblings; // повертаємо масив зі всіма сусідніми елементами
};

const findPrevElements = (element) => findSiblingElements(element, 'previous'); // функція для пошуку всіх попередніх елементів
const findNextElements = (element) => findSiblingElements(element, 'next'); // функція для пошуку всіх наступних елементів

// як використовувати функції findPrevElements та findNextElements
// const element = document.querySelector('.element');
// const prevElements = findPrevElements(element);
// const nextElements = findNextElements(element);

// ======================================================

// Функція яка буде задавати або прибирати клас active у елемента, переданого в якості аргументу

const toggleActiveClass = (elements) => {
    elements.forEach((element) => { // перебираємо всі елементи
        element.addEventListener('click', () => { // додаємо слухача події на кожен елемент
            element.classList.toggle('active'); // додаємо або прибираємо клас active
        });
    });
};

// як використовувати функцію toggleActiveClass
// const element = document.querySelectorAll('.element');
// toggleActiveClass(element);

// ======================================================

// Функція для прокрутки сторінки до вказаного елемента
// element: елемент DOM, до якого потрібно прокрутити сторінку
// triggerSelector: селектор елемента-тригера, який має бути натиснутий перед прокруткою
// offset: відступ від верху сторінки після прокрутки
const scrollToElement = (element, triggerSelector = null, offset = 0) => {
    // Якщо передано селектор елемента-тригера
    if (triggerSelector) {
        // Знаходимо елемент-тригер
        const triggerElement = document.querySelector(triggerSelector);
        // Якщо елемент-тригер знайдено, натискаємо на нього
        if (triggerElement) {
            triggerElement.click();
        }
    }

    // Налаштування для прокрутки
    const scrollOptions = {
        behavior: 'smooth', // прокрутка з плавною анімацією
        block: 'start', // відносно верхньої частини елемента
        inline: 'nearest', // відносно найближчої країни елемента
    };

    // Створюємо об'єкт IntersectionObserver для спостереження елемента
    const scrollElement = new IntersectionObserver((entries, observer) => {
        // Обробляємо кожен запис спостереження
        entries.forEach(entry => {
            // Якщо елемент перетинає область видимості
            if (entry.isIntersecting) {
                // Прокручуємо сторінку на вказаний відступ
                window.scrollBy({
                    top: offset,
                    behavior: 'smooth'
                });
                // Відключаємо спостерігач, коли прокрутка завершена
                observer.disconnect();
            }
        });
    }, scrollOptions);

    // Розпочинаємо спостереження за елементом
    scrollElement.observe(element);
};

// як використовувати функцію scrollToElement
// const element = document.querySelector('.element');
// scrollToElement(element);
// як використовувати функцію scrollToElement зі селектором елемента-тригера
// const element = document.querySelector('.element');
// const triggerSelector = '.trigger';
// scrollToElement(element, triggerSelector);
// як використовувати функцію scrollToElement зі селектором елемента-тригера та відступом
// const element = document.querySelector('.element');
// const triggerSelector = '.trigger';
// const offset = 100;
// scrollToElement(element, triggerSelector, offset);

// ======================================================

// функція, яка прокручує сторінку до верху

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
};

// як використовувати функцію scrollToTop
// scrollToTop();

// ======================================================

// Функція для управління видимістю кнопки прокрутки до верху сторінки
const scrollToTopButton = () => {
    const button = document.getElementById('back-top');
    const progressRing = button.querySelector('.progress-ring__circle');
    const radius = progressRing.r.baseVal.value;
    const circumference = 2 * Math.PI * radius;

    progressRing.style.strokeDasharray = `${circumference} ${circumference}`;
    progressRing.style.strokeDashoffset = circumference;

    button.style.display = "none";

    let isThrottled = false;
    let lastScrollTop = 0;

    const updateProgress = () => {
        if (isThrottled) return;
        isThrottled = true;

        requestAnimationFrame(() => {
            const scrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
            const scrollTop = document.documentElement.scrollTop;
            const scrollPercentage = (scrollTop / scrollHeight) * 100;
            const offset = circumference - scrollPercentage / 100 * circumference;

            progressRing.style.strokeDashoffset = Math.max(0, offset);

            // Показати або приховати кнопку, коли прокручено 10% від сторінки
            const showAfterScrolledPercentage = 10; // 10% від висоти сторінки
            const showOnScroll = scrollHeight * showAfterScrolledPercentage / 100;
            button.style.display = scrollTop > showOnScroll ? 'grid' : 'none';

            isThrottled = false;
        });

        lastScrollTop = document.documentElement.scrollTop;
    };

    const throttleScroll = (event) => {
        if (!isThrottled || Math.abs(document.documentElement.scrollTop - lastScrollTop) > 50) {
            updateProgress();
        }
    };

    window.addEventListener('scroll', throttleScroll);
    button.addEventListener('click', scrollToTop);
};

// ======================================================

const toggleMenu = () => {
    try {
        const menu = document.querySelector('.header__menu'),
              menuToggle = document.querySelector('.menu-toggle'),
              menuClose = document.querySelector('.header__menu-close'),
              overlay = document.getElementById('menu-overlay');
    
        function closeMenu() {
            menu.classList.remove('active');
            document.body.classList.remove('no-scroll');
            overlay.classList.remove('active');
        }
    
        menuToggle.addEventListener('click', () => {
            menu.classList.add('active');
            document.body.classList.add('no-scroll');
            overlay.classList.add('active');
        });
    
        menuClose.addEventListener('click', () => {
            closeMenu();
        });
    
        overlay.addEventListener('click', () => {
            closeMenu();
        });
    } catch (error) {
        
    }
};

// викликаємо необхідні функції, коли завантажено DOM

document.addEventListener('DOMContentLoaded', () => {

    // плавна прокрутка до якоря
    document.querySelectorAll('a[href^="#"]').forEach(anchor => { 
        anchor.addEventListener('click', function (e) {
            e.preventDefault();

            document.querySelector(this.getAttribute('href')).scrollIntoView({
                behavior: 'smooth'
            });
        });
    });

    // відкриття/закриття мобільного меню
    toggleMenu();

});

// Path: src/js/app.js